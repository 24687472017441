import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Flex from "../components/Flex";
import styles from "../styles/pages/condicoes.module.scss";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/SEO";

const TermsConditionsPage: FunctionComponent<RouteComponentProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "backgrounds/terms.svg" }
      ) {
        publicURL
      }
      yellowDots: file(
        relativePath: { eq: "backgrounds/yellow-dots.svg" }
      ) {
        publicURL
      }
    }
  `);
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "seo_title_condicoes" })}
        description={intl.formatMessage({ id: "seo_description_condicoes" })}
        keywords={intl.formatMessage({ id: "seo_keywords_condicoes" })}
        canonical_url={`https://next.take-eat.pt/condicoes`}
      />
      <Flex flexDirection="row" alignItems="start" justifyContent="between" className={styles.termsPage}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="end"
          className={styles.imageCol}
        >
          <img src={data.background.publicURL} alt="background image" className={styles.termsImageLeft} />
        </Flex>

        <Flex flexDirection="column" alignItems="start" className={styles.termsCol}>
          <h1 className={styles.termsTitle}>  {intl.formatMessage({ id: "termsPage_title" })}</h1>
          <h2 className={styles.termsSubTitle}> Cookies </h2>
          <p className={styles.termsText}>
            {intl.formatMessage({ id: "termsPage_paragraph_one" })}
          </p>
          <h2 className={styles.termsSubTitle}> Google Analytics </h2>
          <p className={styles.termsText}>
            {intl.formatMessage({ id: "termsPage_paragraph_two" })}
          </p>
          <h2 className={styles.termsSubTitle}> {intl.formatMessage({ id: "termsPage_paragraph_refuse" })} </h2>
          <p className={styles.termsText}>
            {intl.formatMessage({ id: "termsPage_paragraph_three" })}
          </p>
          <p className={styles.termsText}>{intl.formatMessage({ id: "termsPage_paragraph_four" })}</p>
          <p className={styles.termsText}>https://www.cookiesandyou.com/disable-cookies/android/chrome/ </p>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          className={styles.imageCol}
        >
          <img src={data.yellowDots.publicURL} alt="yellow dots image" className={styles.termsImageRight}/>
        </Flex>
      </Flex>
    </Layout>
  )
};

export default TermsConditionsPage;
